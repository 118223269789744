import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { Layout, Container } from 'layouts';

const About = center => (
  <Layout>
    <Helmet title={'Contact'} />
    <Header title="Contact"></Header>
    <Container>
      If you like feel, free to drop me a message below and will get back to you as soon as possible<br/>
      <br/>
      Please don't forget to leave your name and email address, along with your message.<br/>
      <br/>
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div style={{display:"flex", padding:"3px", justifyContent: "center"}}> 
          <div style={{width:"100px"}}><label htmlFor="name">Name</label></div>
          <input type="text" name="name" id="name" style={{width:"400px"}}/>
        </div>

        <div style={{display:"flex", padding:"3px", justifyContent: "center"}}> 
          <div style={{width:"100px"}}><label htmlFor="name">Email</label></div>
          <input type="text" name="email" id="email" style={{width:"400px"}}/>
        </div>

        <div style={{display:"flex", padding:"3px", justifyContent: "center"}}> 
          <div style={{width:"100px"}}><label htmlFor="name">Message</label></div>
          <textarea name="message" id="message" rows="6" style={{width:"400px"}}/>
        </div>
        <div style={{textAlign:"center", margin:"10px 0 0 100px", display:"flex", justifyContent:"space-evenly" }}>
          <input type="submit" value="Send" />
          <input type="reset" value="Clear" />  
        </div> 
      </form>
    </Container>
  </Layout>
);

export default About;

Container.propTypes = {
  center: PropTypes.object,
};
